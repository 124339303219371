import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, Navbar} from 'react-bootstrap';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

function App() {
  const isBlog = window.location.host.split('.')[0].toLowerCase() === 'blog';
  
  
  useEffect(() => {
    document.title = isBlog ? "Pontus Gavelin's Blog" : "Pontus Gavelin - Developer";
  }, [isBlog])

  const blogUrl = `https://blog.pontusgavelin.com`

  if(isBlog) {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Row className="vh-100 w-100 align-items-center">
              <Col>
            <h1 className="display-1 text-center text-muted text-wrap">Soon to be my technical blog</h1>
              </Col>
            </Row>
          </Route>
        </Switch>
      </Router>
    )
  } else {

    return (
      <React.Fragment>
      <Router>
        <Navbar fixed="top" style={{backgroundColor: 'rgba(130, 230, 255, 0.3)'}}>
          <Container>
            <Navbar.Brand>PONTUS GAVELIN</Navbar.Brand>  
            <Navbar.Collapse className="justify-content-end">
              <a href={blogUrl}>my blog</a>
            </Navbar.Collapse>
          </Container>  
        </Navbar>
      <Switch>
        <Route exact path="/" >
          <Container className="vh-100" >
            <Row className="align-items-center h-75 w-100">
              <Col className="my-auto">
                <h1 className="display-1 text-center text-muted">PONTUS GAVELIN</h1>
                  <hr className="w-100"/>
                <p className="text-center lead">software developer</p>
                <p className="text-center">
                  <span role="img" aria-label="Laptop emoji">💻</span>
                </p>
              </Col>
            </Row>
          </Container>
        </Route>
      </Switch>
    </Router>
      </React.Fragment>
  );
  }
}

export default App;

